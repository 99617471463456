import React from "react";
// import { Link } from "react-router-dom";
import FileSaver from "file-saver";

import logo from "../valmet-logo-negative.svg"; // Tell webpack this JS file uses this image
import Geolocation from "../Geolocation"
export default function Header( {headerClick} ) {
  // eslint-disable-next-line  no-unused-vars
  const testJSON = () => {
    // console.log("keys");
    // console.log(Object.keys(localStorage));

    console.log(localStorage.getItem(Object.keys(localStorage)[0]));

    var data = {};

    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      data[key] = JSON.parse(localStorage.getItem(key));
    }

    console.log("before stringify");
    console.log(data);

    var jsonData = JSON.stringify(data);
    console.log("after stringify");
    console.log(jsonData);
    // var jsonParsed = jsonData.replace(/\\/g, "");

    // console.log("after parsing");
    // console.log(jsonParsed);

    var blob = new Blob([jsonData], { type: "application/json" });

    FileSaver.saveAs(blob, "localStorage.json");
  };
  // eslint-disable-next-line  no-unused-vars
  const getGeolocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        console.log("Altitude is: ", position.coords.altitude)
        // alert("Latitude is: " + position.coords.latitude);
        // alert("Longitude is: " + position.coords.longitude);
        // alert("Altitude is: " + position.coords.altitude)
      },
      (error) => {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  };
  /* QR with {ID} serialNumber: {serialNumber} model: {model} */
  return (
    <header style={headerStyle} onClick={() => headerClick()}>
      <img style={logoStyle} src={logo} alt="Logo"></img>
      <h3>Valmet QR Links</h3>
      <button onClick={getGeolocation}>Update location</button><text>{Geolocation()}</text>
      {
      /* 
      <input type="text" id="FloorInput" pattern="[0-9]*" onInput={setFloorClick} value={floor} />

      <Link style={linkStyle} to="/">
      Home
      </Link>{" "}
      |{" "}
      <Link style={linkStyle} to="/qr">
        QR{" "}
      </Link>{" "}
      | {" "}
      <button style={headerButtonStyle} onClick={getGeolocation}>
        Get Geolocation
      </button> */}
    </header>
  );
}

const headerStyle = {
  background: "#333",
  color: "#fff",
  textAlign: "center",
  padding: "10px",
  cursor: "pointer"
};

// const linkStyle = {
//   color: "#fff",
//   textDecoration: "none",
// };

const logoStyle = {
  height: "80px",
};

// const headerButtonStyle = {
//   background: "#333",
//   color: "#fff",
//   border: "none",
// };
