import React, { Component } from "react";
import { Card, Button, Modal } from "react-bootstrap";
// eslint-disable-next-line  no-unused-vars
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// eslint-disable-next-line  no-unused-vars
import { faCamera, faQrcode, faSave, faAlignRight } from "@fortawesome/free-solid-svg-icons";

import Camera from "../Camera";
import Container from "../layout/Container";

export default class Output extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extraMenu: false,
      showCamera: false,
      showScanner: false,
      latitude: "",
      longitude: "",
    };
  }

  getLink = () => {
    let link;
    if (this.props.onlyActive) {
      link =
        "m-files://search/" +
        process.env.REACT_APP_VAULT +
        "?objecttype=" +
        process.env.REACT_APP_OBJECT_TYPE +
        "&propertyvalue=1273/T" +
        this.props.result +
        "&propertyvalue=39/239";
    } else {
      link =
        "m-files://search/" +
        process.env.REACT_APP_VAULT +
        "?objecttype=" +
        process.env.REACT_APP_OBJECT_TYPE +
        "&propertyvalue=1273/T" +
        this.props.result;
    }
    console.log("mfiles link: " + link);
    return link;
  };

  getMobileServiceLink = () => {
    let link = "https://eu.mf.valmet.com/link/openlink.aspx?link=m-files://newobject/D23E45F7-94CD-4AF5-9A26-233B74219208/0?&" +
    "property=1147/TMobile%20service%20report&property=100/882&property=1273/T" +
    // this.props.result;
    this.props.serialNumber;
    console.log("service report link: " + link)
    return link
  }

  getAttachmentLink = () => {
    let link = "m-files://newobject/D23E45F7-94CD-4AF5-9A26-233B74219208/0?&property=1213/TEquipment%20attachment&property=100/881&property=1273/T" +
    // this.props.result;
    this.props.serialNumber;
    console.log("service report link: " + link)
    return link
  }

  getServiceHistoryLink = () => {
    // TODO Map EQtype to objectType and propertyValue
    let link = "https://eu.mf.valmet.com/link/openlink.aspx?link=m-files://search/D23E45F7-94CD-4AF5-9A26-233B74219208?objecttype=0&propertyvalue=1273/T" + 
    // this.props.result +
    this.props.serialNumber +
    "&propertyvalue=100/35";
    console.log("service history link: " + link)
    return link
  }

  getEquipmentHistoryLink = () => {
    // TODO Map EQtype to objectType and propertyValue
    let link = "https://eu.mf.valmet.com/link/openlink.aspx?link=m-files://search/D23E45F7-94CD-4AF5-9A26-233B74219208?objecttype=0&propertyvalue=1273/T" + 
    // this.props.result +
    this.props.serialNumber +
    "&propertyvalue=100/881"
    console.log("service equipment history link: " + link);
    return link
  }

  getAllHistoryLink = () => {
    // TODO Map EQtype to objectType and propertyValue
    let link = "https://eu.mf.valmet.com/link/openlink.aspx?link=m-files://search/D23E45F7-94CD-4AF5-9A26-233B74219208?objecttype=0&propertyvalue=1273/T" +
    // this.props.result
    this.props.serialNumber;
    console.log("all history link: " + link);
    return link
  }
  // TODO make this easier to expand, perhaps read from JSON file

  scanButtonClick = () => {
    this.setState({
      showScanner: true,
    });
  };

  menuButtonClick = () => {
      this.setState({
        extraMenu: true,
      })
    
    // console.log('opacity: ' + this.state.opacity)
    console.log('extraMenu: ' + this.state.extraMenu)
  }

  handleClose = () => {
    this.setState({
      extraMenu: false
    })
  }

  saveButtonClick = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("Latitude is :", position.coords.latitude);
        console.log(" is :", position.coords.longitude);
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        localStorage.setItem(
          this.props.result,
          JSON.stringify({
            location: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            photo: "",
          })
        );
      },
      (error) => {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    
    )
  }

  render() {
    return (
      <div>
        {!this.state.showCamera && !this.state.showScanner && (
          <>
            <Card className="text-center mb-2 bg-success text-white">
              <Card.Body>
                <Card.Title>Serial number: {this.props.serialNumber} Model: {this.props.model}</Card.Title>
                {/* <Card.Text> ID: {this.props.ID} 
                  <Geolocation></Geolocation>
                </Card.Text> */}
              </Card.Body>
            </Card>

            {/* <Button variant="dark" href={this.getLink()}>
              M-FILES
            </Button> */}
            { /* FOR QR CODE Scan <FontAwesomeIcon icon={faQrcode} /> */}
            <>
              <Button variant="dark" size="wide" href={this.getMobileServiceLink()}>
                Service Report
              </Button>

              <Button variant="dark" size="wide" href={this.getAttachmentLink()}>
                Add Attachments
              </Button>

              <Button variant="dark" size="wide" onClick={this.scanButtonClick}>
                Scan QR code
              </Button>

              <Button variant="dark" size="wide" onClick={this.menuButtonClick}>
                More
              </Button>
            </>
            {/* <Button variant="dark" size="menu" onClick={this.menuButtonClick}>
                <FontAwesomeIcon icon={faAlignRight} />
            </Button> */}
            {/* <Button variant="dark" size="wide" onClick={this.saveButtonClick}>
              <FontAwesomeIcon icon={faSave} />
            </Button> */}
          </>
        )}

        {this.state.showCamera && <Camera result={this.props.result} />}

        {this.state.showScanner && <Container />}

        <Modal show={this.state.extraMenu} onHide={this.handleClose} centered>
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body>
            <Button variant="dark" size="wide" href={this.getServiceHistoryLink()}>
                Search Service Reports
            </Button>
            <Button variant="dark" size="wide" href={this.getEquipmentHistoryLink()}>
                Search Equipment Attachments
            </Button>
            <Button variant="dark" size="wide" href={this.getAllHistoryLink()}>
                Search All Documents
            </Button>
          </Modal.Body>
            <Modal.Footer>
              <Button variant="dark" onClick={this.handleClose}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>

      </div>
    );
  }
}
