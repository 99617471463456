import React, { Component } from "react";

export default class CenterView extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
