import React from "react";
import Webcam from "react-webcam";
import { Button } from "react-bootstrap";
import { isMobile, isBrowser } from "react-device-detect";
import FileSaver from "file-saver";

export default function Camera(props) {
  //   const [dimensions, setDimensions] = React.useState({
  //     height: window.innerHeight,
  //     width: window.innerWidth
  //   })
  //   React.useEffect(() => {
  //     function handleResize() {
  //       setDimensions({
  //         height: window.innerHeight,
  //         width: window.innerWidth
  //       })

  //     window.addEventListener('resize', handleResize)
  //   })
  // }

  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  const capture = React.useCallback(() => {
    console.log("capture button click");
    const imageSrc = webcamRef.current.getScreenshot();
    console.log("imageSrc " + imageSrc);
    var blob = b64toBlob(imageSrc, "image/jpeg");
    var saveName = props.result;
    FileSaver.saveAs(blob, saveName);
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc, props.result]);

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var splittedb64 = b64Data.split(",");
    // console.log(splittedb64);

    var byteCharacters = atob(splittedb64[1]);
    // console.log("byteCharacters " + byteCharacters);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const videoConstraintsMobile = {
    facingMode: { exact: "environment" },
  };

  const videoConstraintsBrowser = {
    // width: window.innerWidth,
    // height: window.innerHeight,
  };

  return (
    <div>
      {isMobile && (
        <Webcam
          videoConstraints={videoConstraintsMobile}
          // width={window.innerWidth}
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
        />
      )}

      {isBrowser && (
        <div className="div-centered">
          <Webcam
            videoConstraints={videoConstraintsBrowser}
            height={720}
            width={1280}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
          />
        </div>
      )}

      <Button className="cameraButton" variant="dark" size="wide" onClick={capture}>
        Capture
      </Button>
      {imgSrc && <img alt="xd" src={imgSrc} />}
    </div>
  );
}
