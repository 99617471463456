import React, { useState, useEffect } from 'react'

import QrReader from "react-qr-reader";
// eslint-disable-next-line  no-unused-vars
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Perhaps we want to use icons
// eslint-disable-next-line  no-unused-vars
import { faQrcode } from "@fortawesome/free-solid-svg-icons"; // Perhaps we want to use icons
// import { serialNumber, EQtype } from "../../serviceWorker"; // TODO use these from URL instead of camera photo this.props.result
import Output from "./Output";
import CenterView from "./CenterView";

import { Button, Alert, Modal } from "react-bootstrap";
import { setFloorValue } from '../Geolocation';

const Container = ( { home } ) => {
    // console.log('home in container: ' + home);
    console.log("Current URL: " + window.location.href);
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const _ID = params.get("ID"); // Can be missing / empty
    console.log("URL ID: " + _ID);
    const _serialNumber= params.get("serialNumber"); // Can be missing / empty
    console.log("URL serialNumber: " + _serialNumber);
    const _model = params.get("EQtype");             // Can be missing / empty
    console.log("URL with EQtype: " + _model);

    // const [result, setResult] = useState("") // next line initialize with URL serialNumber
    const [result, setResult] = useState(_serialNumber)
    const [ID, setID] = useState(_ID)
    const [serialNumber, setSerialNumber] = useState(_serialNumber)
    const [model, setModel] = useState(_model)
    // eslint-disable-next-line  no-unused-vars
    const [floor, setFloor] = useState(0)

    // eslint-disable-next-line  no-unused-vars
    const [delay, setDelay] = useState(300)
    console.log("SerialNumber: " + serialNumber + " EQtype: " + _model);

    const [showOutput, setShowOutput] = useState(false)
    const [showReader, setShowReader] = useState(false)
    // eslint-disable-next-line  no-unused-vars
    const [showCamera, setShowCamera] = useState(false)
    const [showButton, setShowButton] = useState(true)
    const [showActive, setShowActive] = useState(false)
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
      console.log('home changed useEffect')
      setShowButton(true)
      setShowOutput(false)
      setShowReader(false)
      setShowCamera(false)
      setShowActive(false)
    }, [home]);

    
    const handleScan = (data) => {
        if (data) {
          console.log(data);
          // console.log("object values" + Object.values(this.state.result));
    
          // setShowButton(true)
          // setShowOutput(true)
          // setShowReader(false)
          // setShowCamera(false)
          // setShowActive(false)
          setShowModal(true)
          setResult(data) // TODO parse from data/URL serialNumber and model/EQtype
          // localStorage.setItem(data.toString(), { location: "altitude: , latitude: ", photo: "c://", });
          if (serialNumber && serialNumber.length > 0) {
            localStorage.setItem(serialNumber, { location: "altitude: , latitude: ", photo: "c://", }); // TEST
          }
        }
      };
    
      const handleError = (err) => {
        console.error(err);
      };
      // eslint-disable-next-line  no-unused-vars
      const setFloorClick = () => {
        const fl = document.getElementById('FloorInput').value
        setFloor(fl)
        setFloorValue(fl)
      }
      
      const scanButtonClick = () => {
        // this.setState({
        //   showButton: false,
        //   showReader: true,
        //   showOutput: false,
        // });
        setShowButton(false)
        setShowReader(true)
        setShowOutput(false)
      };
    
      const handleCheckboxChange = () => {
        // this.setState({
        //   showActive: !this.state.showActive,
        // });
        setShowActive(!showActive)
      };
    
      const testButtonClick = () => {
        console.log("Continue...");
        setShowModal(true)
        setShowButton(true)
        setShowOutput(true)
        setShowReader(false)
        setShowCamera(false)
        setShowActive(false)
        // If we have serialNUmber then use it
        if (serialNumber && serialNumber.length > 0) {
          setResult(serialNumber);
          setSerialNumber(serialNumber);
        }
        else {
          console.error("No serial number available!");
          // setResult(1028);
        }
        if (model && model.length > 0) {
          console.log("Model: " + model);
          setModel(model);
        }
        else {
          console.error("No model available!");
        }
        if (ID && ID.length > 0) {
          console.log("ID: " + ID);
          setID(ID);
        }
        else {
          console.error("No ID available!");
        }
        if (serialNumber && serialNumber.length) {
          localStorage.setItem(serialNumber, JSON.stringify({location: "altitude: , latitude: ", photo: "c://" }));
        }
        // console.log(localStorage.getItem("1028"));
        console.log(JSON.stringify(localStorage.getItem(serialNumber))); // This will need perhaps ID or model + "." + serialNumber
      };

      const handleClose = () => {
        setShowModal(false)
        setShowButton(true)
        setShowOutput(true)
        setShowReader(false)
        setShowCamera(false)
        setShowActive(false)
      }
    /* Button <FontAwesomeIcon icon={faQrcode} /> */
    return (
        <div style={{ marginTop: "2px" }}>
            {showReader && (
              <>
                <div>
                    <QrReader
                        delay={delay}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: "100%" }}
                    />
                    <Button variant="dark" size="wide" onClick={testButtonClick}>
                        Continue...
                    </Button>
                </div>

                
                </>
            )}

            <Modal show={showModal} onHide={handleClose} centered>
              {' '}

              <Alert variant='success'>
                <Alert.Heading>
                QR with serialNumber: {serialNumber} model: {model}
                </Alert.Heading>
              </Alert>

            </Modal>
            
            {showOutput && (
                <Output
                    result={result}
                    onlyActive={showActive}
                    // ID={ID}
                    serialNumber={serialNumber}
                    model={model}
                />
            )}

            {showButton && !showOutput && (
                <CenterView>
                    { /* <input type="text" id="FloorInput" pattern="[0-9]*" onInput={setFloorClick} value={floor} /> */ }
                    <Button variant="dark" size="wide" onClick={scanButtonClick}>Scan QR code</Button>
                    <Button variant="dark" size="wide" onClick={testButtonClick}>
                      Continue with SerialNumber: {serialNumber} Model: {model}
                    </Button>
                    <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <form>
                        <label>
                        Search only active:
                        <input
                            name="onlyActive"
                            type="checkbox"
                            checked={showActive}
                            onChange={handleCheckboxChange}
                        ></input>
                        </label>
                    </form>
                    </div>
                </CenterView>
                
            )}
        </div>
    )
}

export default Container
