import React, { Component } from "react";
import Header from "./components/layout/Header";
import Container from "./components/layout/Container"
// import NfcReader from "./components/NfcReader";

import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      home: true,
    };
  }


  // when clicking header go to menu page
  headerClick = () => {
    this.setState({
      home: !this.state.home,
    },
    function () {
      console.log('header click in app: ' + this.state.home);
    }
    );
  }
  

  render() {
    return (
        <div className="App">
          <Header headerClick={this.headerClick}/>
          {/* <QrReader home={this.state.home}/> */}
          <Container home={this.state.home}/>
          {/* home={this.state.home} */}
        </div>
    );
  }
}

export default App;
