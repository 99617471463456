import React, {useState} from 'react'

export let floor;

export function setFloorValue(value) {
  floor = value;
}

export default function Geolocation() {
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [altitude, setAltitude] = useState(0);
  var id, options;

  function success(position) {
    console.log("Latitude:     ", position.coords.latitude);
    console.log("Longitude:    ", position.coords.longitude);
    console.log("Accuracy:     ", position.coords.accuracy);
    console.log("Altitude:     ", position.coords.altitude);
    console.log("ALT Accuracy: ", position.coords.altitudeAccuracy);
    console.log("Floor:        ", floor);
    console.log("Heading:      ", position.coords.heading);
    setLatitude(position.coords.latitude)
    setLongitude(position.coords.longitude)
    setAltitude(position.coords.altitude)
  }

  function error (err) {
    console.warn('ERROR(' + err.code + '): ' + err.message);
  }

  options = {
    enableHighAccuracy: true,
    timeout: 5000,
   maximumAge: 30000
  };

  id = navigator.geolocation.watchPosition(success, error, options);
  console.log("watchposition id: " + id)

  if (altitude === null && floor === undefined) {
    return (
      <div>
        Lat: {latitude.toFixed(4)} Lon: {longitude.toFixed(4)}
      </div>
    )
  }
  if (altitude && floor === undefined) {
    return (
      <div>
        Lat: {latitude.toFixed(4)} Lon: {longitude.toFixed(4)} Alt: {altitude.toFixed(4)}
      </div>
    )
  }
  if (floor && altitude === null) {
    return (
      <div>
        Lat: {latitude.toFixed(4)} Lon: {longitude.toFixed(4)} Floor: {floor}
      </div>
    )
  }

}
